import { useStaticQuery, graphql } from 'gatsby';

/**
 * Query for the carousel images
 */
export const useCarouselImages = () => {
  const data = useStaticQuery(graphql`
    query CarouselImages {
      allFile(
        filter: {
          extension: { regex: "/jpg/" }
          dir: { regex: "/assets/carousel-images/" }
        }
      ) {
        edges {
          node {
            id
            relativePath
            childImageSharp {
              fluid(maxWidth: 1200, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);
  let images = data.allFile.edges.map(edge => edge.node);
  return images;
};
