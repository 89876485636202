import { Link } from 'gatsby';
import React from 'react';
import './contact-banner.scss';

/**
 * The banner with yellow background on the main page
 */
export const ContactBanner = () => {
  return (
    <div className="contact-banner">
      <div>
        <p>unsure where to start?</p>
        <Link to="/contact" className="contact-link">
          contact one of our <span>team</span>
        </Link>
      </div>
    </div>
  );
};
