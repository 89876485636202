import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';
import { useCarouselImages } from './header-carousel-images-hook';

import './header-carousel.scss';

const items = [
  {
    src: 'header-recruitment.jpg',
    altText: 'Recruitment by Aphex',
    caption: (
      <>
        Presenting high quality opportunities to candidates and high quality
        candidates to clients
      </>
    )
  },
  {
    src: 'header-chemishield.jpg',
    altText: 'Chemishield by Aphex',
    caption: (
      <>
        The world’s first digital solution for solid and liquid waste management
      </>
    )
  },
  {
    src: 'header-validation.jpg',
    altText: 'Validation by Aphex',
    caption: (
      <>
        Delivering ‘best in class’ validation expertise, alleviating your
        compliance headaches
      </>
    )
  }
];

/**
 * Carousel displayed at the top of the main page
 * @param {any} props
 */
export const HeaderCarousel = props => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const imageData = useCarouselImages();

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map(item => {
    const data = imageData.find(image => image.relativePath.includes(item.src))
      .childImageSharp.fluid;
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <img
          src={data.src}
          srcSet={data.srcSet}
          sizes={data.sizes}
          alt={item.altText}
        />
        <CarouselCaption captionText={item.caption} captionHeader={null} />
      </CarouselItem>
    );
  });

  return (
    <div className="header-carousel-container">
      <Carousel
        activeIndex={activeIndex}
        next={next}
        previous={previous}
        ride="carousel"
      >
        <CarouselIndicators
          items={items}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
        {slides}
        <CarouselControl
          direction="prev"
          directionText="Previous"
          onClickHandler={previous}
        />
        <CarouselControl
          direction="next"
          directionText="Next"
          onClickHandler={next}
        />
      </Carousel>
    </div>
  );
};
