import React, { useState } from 'react';

import './case-studies.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCaseStudies } from './case-studies-hook';
import { useCompanyLogos } from './company-logos-hook';
import { CSSTransition } from 'react-transition-group';

/**
 * Case studies displayed on the main page
 */
export const CaseStudies = () => {
  const [page, setPage] = useState(0);
  const [direction, setDirection] = useState('left');

  const yaml = useCaseStudies();
  const logos = useCompanyLogos();

  const pageCount = yaml.length;

  const next = () => {
    if (page + 1 < pageCount) {
      setPage(page + 1);
      setDirection('left');
    }
  };

  const prev = () => {
    if (page >= 1) {
      setPage(page - 1);
      setDirection('right');
    }
  };

  return (
    <div className="case-studies">
      <div>
        <h2>client case studies</h2>
        <div className="page-slider">
          {yaml.map((data, i) => {
            const logo = logos.find(logo =>
              logo.relativePath.includes(data.logo)
            );
            return (
              <CSSTransition
                in={page === i}
                key={i}
                classNames={direction}
                timeout={500}
                appear={true}
              >
                <div className="page">
                  <div>
                    <div>
                      {logo && (
                        <img
                          src={logo.publicURL}
                          className="img-fluid"
                          alt=""
                        />
                      )}
                    </div>
                    <div>
                      <h3>{data.title}</h3>
                      <hr data-category={data.category} />
                      {data.content.map((p, i) => (
                        <p key={i}>{p}</p>
                      ))}
                    </div>
                  </div>
                </div>
              </CSSTransition>
            );
          })}
        </div>
        <div className="page-controls">
          <button onClick={prev} className="prev-page" disabled={page === 0}>
            <FontAwesomeIcon icon="angle-left" />
          </button>
          <button
            onClick={next}
            className="next-page"
            disabled={page + 1 === pageCount}
          >
            <FontAwesomeIcon icon="angle-right" />
          </button>
        </div>
      </div>
    </div>
  );
};
