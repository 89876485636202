import { useStaticQuery, graphql } from 'gatsby';

/**
 * Query all the yaml files in the data/case-studies folder
 */
export const useCaseStudies = () => {
  const data = useStaticQuery(graphql`
    query AllCaseStudies {
      allCaseStudies {
        edges {
          node {
            title
            content
            category
            logo
          }
        }
      }
    }
  `);
  let studies = data.allCaseStudies.edges.map(edge => edge.node);
  return studies;
};
