import React from 'react';
import { useCompanyLogos } from './company-logos-hook';

import './company-list.scss';

/**
 * List of greyscale company icons on the main page
 */
export const CompanyList = () => {
  const logos = useCompanyLogos();
  return (
    <div className="company-list">
      <div>
        {logos
          .filter(logo => logo.relativePath.includes('greyscale'))
          .map(logo => {
            return (
              <img
                key={logo.id}
                src={logo.publicURL}
                alt=""
                className={logo.publicURL
                  .substring(
                    logo.publicURL.lastIndexOf('/') + 1,
                    logo.publicURL.lastIndexOf('.')
                  )
                  .replace(' ', '-')
                  .toLowerCase()}
              />
            );
          })}
      </div>
    </div>
  );
};
