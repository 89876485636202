import React from 'react';

import './aphex-divisions.scss';

import RecruitmentLogo from '../assets/svg/recruitment-logo.svg';
import ChemishieldLogo from '../assets/svg/chemishield-logo.svg';
import ValidationLogo from '../assets/svg/validation-logo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ActionLink = props => (
  <a href={props.to} className="action-links" target="_blank">
    <span>{props.children}</span>
    <span>
      <FontAwesomeIcon icon="arrow-right" />
    </span>
  </a>
);

/**
 * Colored boxes for each division of the Aphex Group, displayed on main page
 */
export const AphexDivisions = () => {
  return (
    <div id="aphex-divisions">
      <div className="recruitment-card">
        <div>
          <div className="logo-container">
            <RecruitmentLogo />
          </div>
          <p>
            Recruitment by Aphex provides permanent, temporary, contract and
            contractor based recruitment services.
          </p>
          <p>
            We are uniquely positioned to facilitate the pairing of talent and
            industry.
          </p>
        </div>
        <ActionLink to="https://recruitmentbyaphex.com">
          START MY JOB SEARCH
        </ActionLink>
      </div>
      <div className="chemishield-card">
        <div>
          <div className="logo-container">
            <ChemishieldLogo />
          </div>
          <p>
            Our mission statement is to ‘safeguard our people and environment
            from the dangers of hazardous waste’.
          </p>
          <p>
            Chemishield is a waste management system for both solid and liquid
            waste.
          </p>
        </div>
        <ActionLink to="https://chemishield.com">
          MANAGE MY WASTE BETTER
        </ActionLink>
      </div>
      <div className="validation-card">
        <div>
          <div className="logo-container">
            <ValidationLogo />
          </div>
          <p>
            Validation by Aphex provides engineering and validation consultancy
            services to Life Science companies.
          </p>
          <p>
            We work smarter and harder to deliver validation solutions without
            the red tape.
          </p>
        </div>
        <ActionLink to="https://validationbyaphex.com">
          ALLEVIATE MY HEADACHES
        </ActionLink>
      </div>
    </div>
  );
};
