import React from 'react';
import { HeaderCarousel } from '../components/header-carousel';
import { NavBar } from '../components/navbar';

import '../components/fa-icons';
import '../scss/global.scss';
import { ContactBanner } from '../components/contact-banner';
import { Footer } from '../components/footer';
import { AphexDivisions } from '../components/aphex-divisions';
import { CompanyList } from '../components/company-list';
import { CaseStudies } from '../components/case-studies';
import { CookieConsent } from '../components/cookie-consent';
import { Head } from '../components/head';

export default function () {
  return (
    <>
      <Head
        title="The Aphex Group"
        description="The Aphex Group delivering 'best in class' recruitment, consultancy and technology solutions."
      />
      <CookieConsent />
      <NavBar />
      <HeaderCarousel />
      <AphexDivisions />
      <CaseStudies />
      <CompanyList />
      <ContactBanner />
      <Footer />
    </>
  );
}
