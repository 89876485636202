import { useStaticQuery, graphql } from 'gatsby';

/**
 * Query for all the company logos in the assets folder
 */
export const useCompanyLogos = () => {
  const data = useStaticQuery(graphql`
    query CompanyLogos {
      allFile(
        filter: {
          extension: { regex: "/svg/" }
          dir: { regex: "/assets/company-logos/greyscale|colour/" }
        }
      ) {
        edges {
          node {
            id
            publicURL
            relativePath
          }
        }
      }
    }
  `);
  let logos = data.allFile.edges.map(edge => edge.node);
  return logos;
};
